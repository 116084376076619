import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ["knowledgeBase", "category"]

  connect() {
    if (!this.knowledgeBaseTarget.value) {
      this.knowledgeBaseChanged()
    }
  }

  knowledgeBaseChanged(event) {
    const knowledgeBase = this.knowledgeBaseTarget
    const category = this.categoryTarget

    Rails.ajax({
      url: knowledgeBase.dataset.url,
      type: 'get',
      data: `id=${knowledgeBase.value}`,
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      },
      beforeSend(xhr, options) {
        xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8')
        // Workaround: add options.data late to avoid Content-Type header to already being set in stone
        // https://github.com/rails/rails/blob/master/actionview/app/assets/javascripts/rails-ujs/utils/ajax.coffee#L53
        return true
      },
      success: (partial) => {
        console.log(partial)

        category.innerHTML = `<option value="" label=""></option>`

        if (!partial) partial = []
        partial.forEach(categoryAsPair => {
          category.innerHTML += `<option value="${categoryAsPair[0]}">${categoryAsPair[1]}</option>`
        })
      },
      error: (e) => {
        console.log('error updating')
        console.log(e)
      }
    })
  }
}
