// import { Controller } from "@hotwired/stimulus"
// import { useClickOutside, useIntersection, useTransition } from 'stimulus-use'
import { useTransition } from 'stimulus-use'
import Rails from "@rails/ujs"
import DropdownController from "./dropdown_controller"
import { template } from 'lodash'
const debounce = require('lodash/debounce')
// import { IntersectionController } from 'stimulus-use'
// import { useTransition } from 'stimulus-use/dist/use-transition'

/* przykład użycia: app/views/design/uix/stimulus.html.erb */

export default class extends DropdownController {
  static targets = ["menu", "input", "list", "searchWait", "selectId", "resourceTemplate"]

  eventCreated = false

  connect() {
    console.log('connect1')

    this.menuTarget.classList.add('hidden')
    this.resourceTemplate = template(this.resourceTemplateTarget.innerHTML)

    useTransition(this, { element: this.menuTarget })

    this.updateSearchDebounce = debounce(this.updateSearchDebounce, 1000).bind(this)
  }

  fillDropdown(event) {
    // select_id powinien miec format "TO_ID_SELECTA_Z_FORMULARZA", wtedy tym dropdownem mozemy wypelnic wlasciwy select w formularzu
    let selectId = event.params.select
    let chosenId = event.params.id

    const headerAvatar = document.getElementById(selectId + '_avatar')
    const headerName = document.getElementById(selectId + '_name')
    const selectedAvatar = document.getElementById(selectId + '_avatar_' + chosenId)
    const selectedName = document.getElementById(selectId + '_name_' + chosenId)

    console.log(headerAvatar)
    console.log(headerName)
    console.log(selectedAvatar)
    console.log(selectedName)

    selectId = selectId.split('to_')[1]
    const selectElement = document.getElementById(selectId)

    if (headerName !== null && selectedName !== null) {
      headerName.innerHTML = selectedName.innerText
    }
    if (selectedAvatar !== null) {
      headerAvatar.innerHTML = selectedAvatar.innerHTML
    } else {
      headerAvatar.innerHTML = ''
    }

    chosenId = chosenId === 0 ? '' : chosenId
    selectElement.value = chosenId
    this.toggle()
  }

  updateSearchInfoShow() {
    this.searchWaitTarget.classList.remove('hidden')
    this.searchWaitTarget.classList.add('block')
  }

  updateSearchInfoHide() {
    this.searchWaitTarget.classList.add('hidden')
    this.searchWaitTarget.classList.remove('block')
  }

  updateSearch(event) {
    this.updateSearchInfoShow()
    this.updateSearchDebounce(event)
  }

  updateSearchDebounce(event) {
    console.log('update')
    const input = this.inputTarget.value
    const url = this.inputTarget.dataset.url
    console.log(encodeURIComponent(input))
    console.log(this.listTarget.innerHTML)

    event.preventDefault()

    Rails.ajax({
      url: url,
      type: 'get',
      data: "query=" + encodeURIComponent(input),
      dataType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      },
      beforeSend(xhr, options) {
        xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8')
        // Workaround: add options.data late to avoid Content-Type header to already being set in stone
        // https://github.com/rails/rails/blob/master/actionview/app/assets/javascripts/rails-ujs/utils/ajax.coffee#L53
        return true
      },
      success: (findElements) => {
        console.log('update success')
        console.log(findElements)
        this.updateSearchInfoHide()

        this.listTarget.innerHTML = ''

        if (findElements.length > 0) {
          findElements.forEach((element) => {
            console.log(element.name + " " + element.id)
            this.itemData = {
              id: element.id,
              name: element.name,
              image_url: element.image_url
            }

            this.listTarget.innerHTML += this.resourceTemplate(this.itemData)
            this.addImage(this.itemData)
          })
        } else {
          this.updateSearchInfoHide()
        }
      },
      error: (e) => {
        console.log('error updating')
        this.updateSearchInfoHide()
      }
    })
  }

  addImage(data) {
    if (data.image_url) {
      const img = document.createElement('img')
      const target = this.selectIdTarget.value + "_avatar_" + data.id

      img.src = data.image_url
      img.alt = ''
      img.width = 25
      img.height = 25
      img.classList.add('circle-image')
      img.style.borderRadius = '50%'

      document.getElementById(target).appendChild(img)
      document.getElementById(target).style.marginRight = "10px"
    }
  }
}
