import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="draft"
export default class extends Controller {
  static values = { url: String, interval: Number }

  connect() {
    this.autoSaveInterval = setInterval(() => {
      this.saveDraft()
    }, this.intervalValue)
    console.log(this.autoSaveInterval)
  }

  getFormDataAsJSON(form) {
    const formData = new FormData(form)
    const object = {}

    formData.forEach((value, key) => {
      // Check if key can be split into object and property
      if (key.includes('[') && key.includes(']')) {
        // Split the key into components
        const components = key.split('[')
        const objectKey = components[0]
        const propertyKey = components[1].replace(']', '')

        // Create the object if it doesn't exist
        if (!object[objectKey]) {
          object[objectKey] = {}
        }

        // Assign the value to the property in the object
        object[objectKey][propertyKey] = value
      } else {
        // Assign the value directly to the key in the object
        object[key] = value
      }
    })

    return JSON.stringify(object)
  }

  saveDraft() {
    console.log('saveDraft')
    const form = this.element
    const json = this.getFormDataAsJSON(form)
    const url = this.urlValue
    const csrfToken = document.querySelector('[name="csrf-token"]').content

    fetch(url, {
      method: 'POST', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken
      },
      body: json,
    })
      .then(response => response.json())
      .then(data => console.log(data))
      .catch((error) => {
        console.error('Error:', error)
      })
  }

  disconnect() {
    clearInterval(this.autoSaveInterval)
  }
}
