import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tax", "net", "gross", "displayedNet", "displayedGross"]
  lastModified = "net"

  connect() {
    console.log("deal tax connect")
    this.displayPrecision = 2
    this.precision = 7
  }

  calculateGross(net, tax, displayPrecision) {
    return (net * (100 + tax) / 100).toFixed(displayPrecision)
  }

  calculateNet(gross, tax, displayPrecision) {
    return (gross * 100 / (100 + tax)).toFixed(displayPrecision)
  }

  calculateTax(net, gross) {
    return parseInt(net === gross ? 0 : 100 * (gross - net) / gross)
  }

  netInput(selfCasted = true) {
    if (selfCasted) this.lastModified = "net"

    const displayedNet = parseFloat(this.displayedNetTarget.value)
    this.netTarget.value = displayedNet

    const net = parseFloat(this.netTarget.value)
    const gross = parseFloat(this.grossTarget.value)
    const tax = parseInt(this.taxTarget.value)

    if (!displayedNet) {
      this.displayedGrossTarget.value = null
      this.grossTarget.value = null
    } else if (displayedNet >= 0 && tax && tax >= 0 && tax < 100) {
      this.displayedGrossTarget.value = this.calculateGross(displayedNet, tax, this.displayPrecision)
      this.grossTarget.value = this.calculateGross(net, tax, this.precision)
    } else if (selfCasted && gross && net >= 0 && net <= gross) {
      this.taxTarget.value = this.calculateTax(net, gross)
    }
  }

  grossInput(selfCasted = true) {
    if (selfCasted) this.lastModified = "gross"

    const displayedGross = parseFloat(this.displayedGrossTarget.value)
    this.grossTarget.value = displayedGross

    const tax = parseInt(this.taxTarget.value)
    const net = parseFloat(this.netTarget.value)
    const gross = parseFloat(this.grossTarget.value)

    if (!gross) {
      this.displayedNetTarget.value = null
      this.netTarget.value = null
    } else if (gross >= 0 && tax && tax >= 0 && tax < 100) {
      this.displayedNetTarget.value = this.calculateNet(gross, tax, this.displayPrecision)
      this.grossTarget.value = displayedGross
      this.netTarget.value = this.calculateNet(gross, tax, this.precision)
    } else if (selfCasted && gross && net >= 0 && net <= gross) {
      this.taxTarget.value = this.calculateTax(net, gross)
    }
  }

  taxInput() {
    if (this.lastModified === "net") {
      this.netInput(false)
    } if (this.lastModified === "gross") {
      this.grossInput(false)
    }
  }
}
