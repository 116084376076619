import Dropzone from "dropzone"
import { Controller } from "@hotwired/stimulus"
import { DirectUpload } from "@rails/activestorage"
import {
  getMetaValue,
  findElement,
} from "helpers"
import { locales } from "helpers/dropzone_locale"
export default class extends Controller {
  static targets = ["input", "hiddenInput", "placeholder"]
  availableLocales = ["en", "pl"]

  connect() {
    this.dropZone = createDropZone(this, this.getLocale())
    this.hideFileInput()
    this.bindEvents()
    Dropzone.autoDiscover = false // necessary quirk for Dropzone error in console
  }

  // ustawienie języka dropzone'a
  getLocale() {
    const locale = this.inputTarget.dataset.locale
    if (this.availableLocales.includes(locale)) {
      return locale
    } else {
      // jeśli nie mamy danego języka to dajemy angielski
      // (dodawanie nowych języków w../helpers/dropzone_locale.js)
      return this.availableLocales[0]
    }
  }

  hideFileInput() {
    this.inputTarget.disabled = true
    this.inputTarget.style.display = "none"
  }

  bindEvents() {
    this.dropZone.on("addedfile", file => {
      setTimeout(() => {
        file.accepted && createDirectUploadController(this, file).start()
      }, 500)
      this.placeholderTarget.classList.add('dz-preview')
      this.placeholderTarget.classList.remove('hidden')
      this.placeholderTarget.parentNode.appendChild(this.placeholderTarget)
    })

    this.dropZone.on("removedfile", file => {
      file.controller && file.controller.removeElement()
      //removeElement(file.controller.hiddenInput)
      if (this.hiddenInputTarget.value.length === 0) {
        this.placeholderTarget.classList.remove('dz-preview')
        this.placeholderTarget.classList.add('hidden')
      }
    })

    this.dropZone.on("canceled", file => {
      file.controller && file.controller.xhr.abort()
    })
  }

  get headers() {
    return { "X-CSRF-Token": getMetaValue("csrf-token") }
  }

  get url() {
    return this.inputTarget.getAttribute("data-direct-upload-url")
  }

  get maxFiles() {
    return this.data.get("maxFiles") || 10
  }

  get maxFileSize() {
    return this.data.get("maxFileSize") || 256
  }

  get acceptedFiles() {
    return this.data.get("acceptedFiles")
  }

  get addRemoveLinks() {
    return this.data.get("addRemoveLinks") || true
  }
}

class DirectUploadController {
  constructor(source, file) {
    this.directUpload = createDirectUpload(file, source.url, this)
    this.source = source
    this.file = file
  }

  start() {
    this.file.controller = this
    this.hiddenInput = this.source.hiddenInputTarget
    this.directUpload.create((error, attributes) => {
      if (error) {
        this.removeElement()
        this.emitDropzoneError(error)
      } else {
        this.file.blob_signed_id = attributes.signed_id
        this.file.url = attributes.signed_url
        console.log('dodano plik ' + this.file.upload.uuid + ', signed_id bloba to ', this.file.blob_signed_id)
        this.hiddenInput.value = this.source.dropZone.files.map(file => file.blob_signed_id)
        this.emitDropzoneSuccess()
      }
    })
  }

  directUploadWillStoreFileWithXHR(xhr) {
    this.bindProgressEvent(xhr)
    this.emitDropzoneUploading()
  }

  bindProgressEvent(xhr) {
    this.xhr = xhr
    this.xhr.upload.addEventListener("progress", event =>
      this.uploadRequestDidProgress(event)
    )
  }

  uploadRequestDidProgress(event) {
    const progress = (event.loaded / event.total) * 100
    findElement(
      this.file.previewTemplate,
      ".dz-upload"
    ).style.width = `${progress}%`
  }

  emitDropzoneUploading() {
    this.file.status = Dropzone.UPLOADING
    this.source.dropZone.emit("processing", this.file)
  }

  emitDropzoneError(error) {
    this.file.status = Dropzone.ERROR
    this.source.dropZone.emit("error", this.file, error)
    this.source.dropZone.emit("complete", this.file)
  }

  emitDropzoneSuccess() {
    this.file.status = Dropzone.SUCCESS
    this.source.dropZone.emit("success", this.file)
    this.source.dropZone.emit("complete", this.file)
  }

  removeElement() {
    console.log('usunięto plik ')
    this.hiddenInput.value = this.source.dropZone.files.map(file => file.blob_signed_id)
  }
}

function createDirectUploadController(source, file) {
  return new DirectUploadController(source, file)
}

function createDirectUpload(file, url, controller) {
  return new DirectUpload(file, url, controller)
}

function createDropZone(controller, locale) {
  return new Dropzone(controller.element, {
    ...{
      url: controller.url,
      headers: controller.headers,
      maxFiles: controller.maxFiles,
      maxFilesize: controller.maxFileSize,
      acceptedFiles: controller.acceptedFiles,
      addRemoveLinks: controller.addRemoveLinks,
      autoQueue: false,
    },
    ...locales[locale]
  })
}
