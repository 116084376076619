import { Controller } from "@hotwired/stimulus"
import { createPopper } from '@popperjs/core'
import { useClickOutside, useTransition } from "stimulus-use"
import { getBackgroundColorInHex, getContrastColorForTagText } from "../helpers"

// Connects to data-controller="tag-picker"
export default class extends Controller {
  static targets = ["mainButton", "mainContainer", "colorSelectContainer", "colorSelectPickerContainer",
    "colorSelectPicker", "tagInputContainer", "colorSelectPickerButton", "colorSelectInput", "colorPick",
    "singleTag", "tagTemplate", "tagInput"]

  existingTags = new Set()

  connect() {
    console.log('tagPicker')
    this.mainContainerTarget.classList.add("hidden")
    this.colorSelectPickerTarget.classList.add("hidden")

    this.bindActions()
    this.findEmptyElement()
    this.fillAllTagColors()

    this.closed = true
    this.singleTagTargets.forEach(tag => {
      this.existingTags.add(tag.dataset.value)
    })

    useTransition(this, { element: this.mainContainerTarget })
    useClickOutside(this, { element: this.element })
  }

  // przypisujemy akcje do wszystkich elementow
  bindActions() {
    this.mainButtonTarget.dataset.action = "click -> tag-picker#toggleTagPicker"
    this.colorSelectPickerButtonTarget.dataset.action = "click -> tag-picker#toggleColorPicker"
    this.mainContainerTarget.dataset.action = "click -> tag-picker#clickOutsideClose"
    this.colorSelectInputTarget.dataset.action = "change -> tag-picker#fillColorFromInput"
    this.tagInputTarget.dataset.action = "change -> tag-picker#fillTagText"
    this.colorPickTargets.forEach(element => { element.dataset.action = "click -> tag-picker#fillColorFromPicker" })
    this.singleTagTargets.forEach(element => { element.querySelector("[data-remove]").dataset.action = "click->tag-picker#removeTag" })
  }

  toggleTagPicker() {
    if (this.mainContainerTarget.classList.contains("hidden")) {
      this.createEmptyTagPlaceholder()
      this.openTagPicker()
    } else {
      this.closeTagPickerAndApplyChanges()
    }
  }

  toggleColorPicker() {
    if (this.colorSelectPickerTarget.classList.contains("hidden")) {
      this.openColorPicker()
    } else {
      this.closeColorPicker()
    }
  }

  fillColorFromInput(e) {
    if (e.target.value.length < 8) {
      this.fillTagColor(e.target.value)
    }
    if (e.target.value.length === 0) {
      this.fillTagColor('#d3d3d3')
    }
  }

  // przy wybraniu koloru z pickera, przerabiamy wartosc jego tla na hexa i wrzucamy w input do dalszej modyfikacji
  fillColorFromPicker(e) {
    const color = getBackgroundColorInHex(e.target)
    this.colorSelectInputTarget.value = color
    this.fillTagColor(color)
  }

  fillTagColor(color) {
    this.colorSelectPickerButtonTarget.style.backgroundColor = color
    this.emptyElement.style.backgroundColor = color
    const textColor = getContrastColorForTagText(color)
    this.emptyElement.querySelector('a').style.color = textColor
    this.emptyElement.querySelector('div').style.color = textColor
    this.colorSelectPickerButtonTarget.style.color = textColor
  }

  fillAllTagColors() {
    this.singleTagTargets.forEach(tag => {
      const color = getBackgroundColorInHex(tag)
      const textColor = getContrastColorForTagText(color)
      tag.querySelector('a').style.color = textColor
      tag.querySelector('div').style.color = textColor
    })
  }

  openTagPicker() {
    this.closed = false
    this.enter()
    if (!this.tagPopper) {
      this.tagPopper = createPopper(this.mainButtonTarget, this.mainContainerTarget)
    }
    this.tagInputTarget.value = ""
  }

  closeTagPickerAndApplyChanges() {
    if (this.closed) return
    this.closed = true
    this.leave()
    if ((this.emptyElement.dataset.value === "") || (this.existingTags.has(this.emptyElement.dataset.value))) {
      this.emptyElement.remove()
      return
    }
    const tagName = this.emptyElement.dataset.value
    const tagColor = getBackgroundColorInHex(this.emptyElement)
    this.callFindOrCreateTag(tagName, tagColor)
  }

  callFindOrCreateTag(tag, color) {
    const data = {
      task_id: this.element.dataset.objectId,
      tag: tag,
      color: color
    }

    fetch(this.element.dataset.submitUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").content
      },
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(data => {
        this.fillTagData(this.emptyElement, data.id)
        this.emptyElement.dataset.created = true
        this.existingTags.add(this.emptyElement.dataset.value)
      })
  }

  fillTagData(tag, id) {
    // tag musi zostac najpierw utworzony na backendzie bo musi miec ID
    // to jest pierwszy element 'a' ktory odpowiada za przekierowanie do listy
    const link = tag.querySelector('a')

    link.href = this.element.dataset.modelUrl + `?tag_ids=${id}`
    tag.id = `tag_${id}`
  }

  openColorPicker() {
    this.colorSelectPickerTarget.classList.remove("hidden")
  }

  closeColorPicker() {
    this.colorSelectPickerTarget.classList.add("hidden")
  }

  createEmptyTagPlaceholder() {
    this.emptyElement = this.findEmptyElement()
    if (!this.emptyElement) {
      const template = this.tagTemplateTarget.content.cloneNode(true)
      this.element.appendChild(template)
    }
    this.emptyElement = this.findEmptyElement()
    this.emptyElement.querySelector("[data-remove]").dataset.action = "click->tag-picker#removeTag"
    const color = getBackgroundColorInHex(this.colorSelectPickerButtonTarget)
    this.fillTagColor(color)
  }

  removeTag(e) {
    const container = e.target.closest('span')
    const data = {
      task_id: this.element.dataset.objectId,
      tag_id: container.id.split('tag_')[1],
    }

    fetch(this.element.dataset.removeUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").content
      },
      body: JSON.stringify(data)
    })
      .then(data => {
        this.existingTags.delete(container.dataset.value)
        container.remove()
      })
  }

  fillTagText(e) {
    // tu targetem jest pierwszy link z gory, drugi jest do usuwania tagu
    const target = this.emptyElement.querySelector('a')
    target.innerText = e.target.value.toLowerCase()
    this.emptyElement.dataset.value = e.target.value.toLowerCase()
  }

  findEmptyElement() {
    const element = this.singleTagTargets.find(element => element.dataset.value === "")
    this.emptyElement = element
    return element
  }

  clickOutside(e) {
    // e.preventDefault()
    this.closeColorPicker()
    this.closeTagPickerAndApplyChanges()
  }
}
