import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tab"]

  connect() {
    console.log('cards connect')

    // this.tabTargets[0].toggleTransition()
  }

  toggleCard(event) {
    this.tabTargets.forEach(card => {
      if (card.id === event.params.id) {
        card.classList.remove('hidden')
      } else {
        card.classList.add('hidden')
      }
    })
  }
}
