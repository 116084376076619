import { Controller } from "@hotwired/stimulus"
import { useTransition, useDispatch } from 'stimulus-use'
import { saveFile } from "../helpers"
// import { IntersectionController } from 'stimulus-use'
// import { useTransition } from 'stimulus-use/dist/use-transition'

/* przykład użycia: app/views/design/uix/stimulus.html.erb */

export default class extends Controller {
  static targets = ["attachment", "slide", "filename", "modal", "imageBackground", "image", "download"]

  eventCreated = false
  currentImage = null
  currentSlide = null
  currentIndex = null
  hidden = this.attachmentTarget.classList.contains('hidden')
  modalOpen = false
  allTargetIds = this.slideTargets.map(element => { return element.id })

  connect() {
    useDispatch(this)
    console.log('connect1')

    useTransition(this, { element: this.attachmentTarget })

    if (!this.hidden) {
      this.close()
    }
  }

  openModal(event) {
    event.preventDefault()
    // zabezpieczenie, chowamy wszystkie inne elementy przy otwieraniu
    this.slideTargets.forEach(element => { element.classList.add('hidden') })
    console.log('openModal')
    this.hidden = false
    this.modalOpen = true
    this.toggleTransition()

    this.currentSlide = this.unhideElementById(event.params.id)
    this.currentIndex = this.allTargetIds.indexOf(this.currentSlide.id)

    this.slideChangeActions()
    this.resizeImageToFit()
    if (this.eventCreated === false) {
      const fn = this.outsideHide.bind(this)
      this.eventCreated = fn
      console.log('eventCreated1')
      document.addEventListener('keydown', fn)
      document.addEventListener("click", fn)
    }
  }

  close(event) {
    console.log('close', event)
    this.hidden = true

    this.leave()
    this.removeEvent()
  }

  // powrzucane jest wszedzie gdzie to ma sie nie odpalac event.preventDefault()
  clickOutside(event) {
    console.log('clickoutside')
    if (event.defaultPrevented) {
      return event
    } else if (!(event.target === this.currentImage) && !this.hidden) {
      this.close(event)
    }
  }

  previousSlide(event) {
    event.preventDefault()
    this.hideElement(this.currentSlide)
    const idsLength = this.allTargetIds.length
    const newIndex = this.currentIndex = (this.currentIndex <= 0 ? idsLength : this.currentIndex) - 1

    const newElement = this.unhideElementById(this.allTargetIds[newIndex])
    this.currentSlide = newElement
    this.slideChangeActions()
  }

  nextSlide(event) {
    event.preventDefault()
    this.hideElement(this.currentSlide)
    const idsLength = this.allTargetIds.length
    let newIndex = this.currentIndex + 1
    newIndex = this.currentIndex = newIndex % idsLength

    const newElement = this.unhideElementById(this.allTargetIds[newIndex])
    this.currentSlide = newElement
    this.slideChangeActions()
  }

  hideElement(oldElement) {
    oldElement ||= this.slideTargets.find(el => el === this.currentSlide)
    oldElement.classList.add("hidden")
  }

  unhideElementById(id) {
    const element = this.slideTargets.find(el => el.id === id)
    element.classList.remove("hidden")
    return element
  }

  outsideHide(event) {
    const key = event.key
    console.log('outsideHide1', event)
    if (key === 'ArrowLeft') { this.previousSlide(event) }
    if (key === 'ArrowRight') { this.nextSlide(event) }
    if (key === 'Escape' && !this.hidden && !this.attachmentTarget.classList.contains('hidden')) {
      console.log('hide called')
      this.close(event)
    }
  }

  removeEvent() {
    if (this.eventCreated !== false) {
      console.log('removeEvent1')
      document.removeEventListener("click", this.eventCreated)
      document.removeEventListener('keydown', this.eventCreated)
      this.eventCreated = false
    }
  }

  toggleDescriptionModal(event) {
    const attachmentOptions = {
      title: event.currentTarget.dataset.title,
      description: event.currentTarget.dataset.description,
      filename: event.currentTarget.dataset.filename,
      attachmentId: event.currentTarget.dataset.id
    }
    const id = `modal_attachment_dialog`
    const targetModal = document.getElementById(id)
    const modalController = this.application.getControllerForElementAndIdentifier(
      targetModal,
      "modal-view"
    )
    modalController.open(attachmentOptions)
  }

  addImage(e) {
    const params = e.params
    console.log(params)
    const title = document.querySelector(`[id='display_title_${params.signed}']`).innerText
    const description = document.querySelector(`[id='display_description_${params.signed}']`).innerText

    this.dispatch('addImage', {
      alt: description,
      filename: params.filename,
      title: title,
    })
  }

  zoomIn(event) {
    event.preventDefault()
    this.currentImage.style.height = (this.currentImage.clientHeight + 100) + "px"
  }

  zoomOut(event) {
    event.preventDefault()
    this.currentImage.style.height = (this.currentImage.clientHeight - 100) + "px"
  }

  setCurrentImage() {
    const image = Array.from(this.currentSlide.querySelectorAll('*')).find(el => el.tagName === 'IMG')
    return image
  }

  resizeImageToFit() {
    const imageHeight = this.currentImage.offsetHeight
    const windowHeight = window.innerHeight
    if (imageHeight >= windowHeight) { this.currentImage.style.height = `${parseInt(windowHeight * 0.7)}px` }
  }

  downloadImage(event) {
    // przez event.preventDefault() czasami lapie svg zamiast a
    event.preventDefault()
    const filename = this.currentImage.dataset.filename
    const url = event.target.tagName.toLowerCase() === 'svg' ? event.target.parentNode.href : event.target.href
    const request = new Request(url)

    fetch(request)
      .then(r => r.blob())
      .then(blob => saveFile(blob.slice(0, blob.size, blob.type), filename))
  }

  slideChangeActions() {
    this.currentImage = this.setCurrentImage()
    this.downloadTarget.href = this.currentImage.src
  }

  disconnect() {
    this.removeEvent()
  }
}
