import { Controller } from "@hotwired/stimulus"
import { DirectUpload } from "@rails/activestorage"
import Editor from "@toast-ui/editor"
import '@toast-ui/editor/dist/i18n/pl-pl'
// Connects to data-controller="tui-editor"
export default class extends Controller {
  static targets = ["editor", "content", "hiddenField", "overlay"]

  connect() {
    this.initializeEditor()
  }

  initializeEditor() {
    if (this.hasEditorTarget) {
      this.editor = new Editor({
        el: this.editorTarget,
        // initialEditType: "WYSIWYG",  < - edytor WYSIWYG, ale z tym przy init nie laduje sie content
        previewStyle: "vertical",
        usageStatistics: false, // wylaczamy wysylanie statystyk przez GA
        hooks: {
          addImageBlobHook: this.handleImageUpload.bind(this) // upload obrazkow do railsow zamiast base64
        },
        language: "pl"
      })
      console.log('editor initialized', this.editor)

      setTimeout(() => this.editor.setMarkdown(this.contentTarget.innerHTML), 0)
      // ustawiamy typ edytora na WYSIWYG, fix dla initialEditType, tez nie dziala, dziwne
      // setTimeout(() => this.editor.changeMode("WYSIWYG", true), 0)

      // uzupelniamy wartosc pola ukrytego z markdownem po kazdej zmianie w edytorze
      this.editor.on('change', () => {
        const markdown = this.editor.getMarkdown()
        this.contentTarget.innerHTML = markdown
      })
    }
  }

  async handleImageUpload(blob, callback) {
    console.log("handleImageUpload", blob, callback)
    this.showLoading()
    const imageURL = await this.uploadImage(blob)
    const altText = this.element.querySelector('#toastuiAltTextInput')
    // po wyslaniu obrazka, wywolaj callback z url obrazka i opcjonalnym tekstem alternatywnym
    callback(imageURL, altText ? altText.value : null)
  }

  async uploadImage(blob) {
    var returnUrl
    const file = new File([blob], blob.name, { type: blob.type })
    const uploadPath = `${this.element.dataset.directUploadUrl}?extended=true&in_text=true&public=true`
    const directUpload = new DirectUpload(file, uploadPath)
    const imagesField = this.hiddenFieldTarget

    const signedId = await new Promise((resolve, reject) => {
      directUpload.create((error, blob) => {
        if (error) {
          console.error("DirectUpload error:", error)
          this.hideLoading()
          reject(error)
        } else {
          console.log("DirectUpload blob:", blob)
          this.hideLoading()
          resolve(blob.signed_id)
        }
      })
    })

    if (signedId) {
      imagesField.setAttribute("value", `${imagesField.value ? imagesField.value + ',' : ''}${signedId}`)
      returnUrl = this.fetchImageUrl(signedId)
      console.log("DirectUpload returnUrl:", returnUrl)
      return returnUrl
    } else {
      console.error("DirectUpload error: signedId is empty")
      return ""
    }
  }

  // do zaimplementowania w kontrolerze railsow
  async fetchImageUrl(signedId) {
    const response = await fetch(`${this.element.dataset.imageUrl}?signed_id=${signedId}`, {
      method: 'GET',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        'Content-Type': 'application/json',
      },
      credentials: 'same-origin',
    })

    const data = await response.json()
    return data.url
  }

  showLoading() {
    console.log("showLoading")
    this.overlayTarget.classList.remove("hidden")
  }

  hideLoading() {
    console.log("hideLoading")
    this.overlayTarget.classList.add("hidden")
  }
}
