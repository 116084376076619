import { insightPaths } from 'src/config/rails-intum-paths'

const IntumInsightKlass = {
  insightPath: insightPaths.insight_reports_path,

  renderReportWidget ({
    host = 'https://radgost.intum.com', elementId = 'intum_insight_report', reportID, jwt, params = {}
  }) {
    console.log('renderReportWidget...')

    var path = `${this.insightPath}/${reportID}/chart.js`
    params.jwt = jwt
    const fullUrl = `${host}${path}?` + new URLSearchParams(params)

    console.log('fullUrl', fullUrl)

    fetch(fullUrl, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(data => {
        console.log('Fetch Success:', data)
        var content = document.querySelector("#" + elementId)
        data.forEach(function (row) {
          if (row.kind === 'html') {
            content.insertAdjacentHTML("beforeend", row.content)
          } else if (row.kind === 'script') {
            const script = document.createElement('script')
            if (row.src) {
              script.src = row.src
              content.appendChild(script)
            } else if (row.content) {
              script.innerHTML = row.content
              content.appendChild(script)
            }
          } else if (row.kind === 'link') {
            const tag = document.createElement('link')
            tag.setAttribute('href', row.href)
            tag.setAttribute('rel', row.rel)
            tag.setAttribute('media', row.media)
            content.appendChild(tag)
          }
        })
        console.log(content)
      })
      .catch((error) => {
        console.error('renderReportWidget Error:', error)
      })
      .finally(function () {
        document.querySelector("#" + elementId).style.display = 'block'
      })
  }, // renderReportWidget

} // IntumInsight

export default IntumInsightKlass
