import ModalController from "./modal_controller"

// Connects to data-controller="client-modal"
export default class extends ModalController {
  static targets = ["container", "note", "tag", "dropdownContainer", "formContainer"]
  connect() {
    this.selectedType = 'chooseClient'
    this.model = this.element.dataset.model
    this.unspacedModel = this.model.split('_')[1]
    this.modelId = this.element.dataset.modelId
  }

  saveClient(event) {
    event.preventDefault()
    if (this.selectedType === 'chooseClient') {
      this.saveClientFromDropdown()
    } else {
      this.saveNewClient()
    }
  }

  saveClientFromDropdown() {
    const clientId = this.dropdownContainerTarget.querySelector(`#${this.unspacedModel}_client_id_${this.modelId}`).value
    const data = {
      [this.unspacedModel]: {
        client_id: clientId
      }
    }
    this.submitUpdate(data)
  }

  async saveNewClient() {
    const name = this.formContainerTarget.querySelector('#name').value
    const prefix = this.formContainerTarget.querySelector('#prefix').value
    const phone = this.formContainerTarget.querySelector('#phone').value
    const email = this.formContainerTarget.querySelector('#email').value
    const responsibleId = this.formContainerTarget.querySelector(`#${this.unspacedModel}_client_responsible_id_${this.modelId}`).value
    const data = {
      [this.unspacedModel]: {
        client_attributes: {
          name: name,
          phone: phone,
          phone_prefix: prefix,
          email: email,
          responsible_id: responsibleId
        }
      }
    }

    const response = await this.submitUpdate(data)
    if (response) {
      for (const [key, value] of Object.entries(response)) {
        const errorField = key.split('.').pop().replace('extracted_', '')
        const inputField = this.formContainerTarget.querySelector(`#${errorField}`)
        const errorText = this.formContainerTarget.querySelector(`#${errorField}-error`)
        inputField.classList.add('border-red-500')
        inputField.classList.add('border-2')
        errorText.classList.remove('hidden')
        errorText.innerText = inputField.value + ' ' + value[0]
      }
    }
  }

  saveNote(event) {
    event.preventDefault()
    const note = this.noteTarget.value
    const tags = this.tagTarget.value.split(',')
    const data = {
      [this.unspacedModel]: {
        tags: tags,
        description: note
      }
    }

    this.submitUpdate(data)
  }

  reloadFrame() {
    if (this.model === 'voip_call') {
      const turboFrame = document.querySelector('turbo-frame#voip_calls_list')
      turboFrame.src = turboFrame.src || window.location
      turboFrame.reload()
    } else {
      window.location.reload()
    }
    this.hide()
  }

  radioChange(event) {
    const radioValue = event.target.value
    if (radioValue === 'chooseClient') {
      this.showDropdown()
    } else {
      this.showForm()
    }
  }

  showForm() {
    this.selectedType = 'newClient'
    const phoneField = this.formContainerTarget.querySelector('#phone')

    if (this.element.dataset.phone) {
      phoneField.value = this.element.dataset.phone
      phoneField.innerText = this.element.dataset.phone
    }
    this.dropdownContainerTarget.classList.add('hidden')
    this.formContainerTarget.classList.remove('hidden')

    const event = new CustomEvent('client-modal:show-form', { detail: { phone: this.element.dataset.phone } })
    const countryPrefixElement = this.element.querySelector('[data-controller="country-prefix"]')
    countryPrefixElement.dispatchEvent(event)
  }

  showDropdown() {
    this.selectedType = 'chooseClient'
    this.dropdownContainerTarget.classList.remove('hidden')
    this.formContainerTarget.classList.add('hidden')

    const event = new CustomEvent('client-modal:show-dropdown')
    // querySelector element with data-controller 'country-prefix'
    const countryPrefixElement = this.element.querySelector('[data-controller="country-prefix"]')
    countryPrefixElement.dispatchEvent(event)
  }

  // zwraca true jeśli wszystko ok, w przeciwnym wypadku zwraca obiekt z arrayami błędów (do walidacji)
  async submitUpdate(data) {
    const url = this.element.dataset.url
    const request = fetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").content
      },
      body: JSON.stringify(data)
    })

    let response

    try {
      response = await request
    } catch (error) {
      console.log('There was an error', error)
    }

    // Uses the 'optional chaining' operator
    if (response?.ok) {
      console.log('Success!')
      this.reloadFrame()
      return true
    } else {
      console.log('Error!')
      const error = await response.json()
      return error
    }
  }
}
