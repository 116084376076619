import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ["knowledgeBase", "entry", "section"]

  connect() {
    if (this.knowledgeBaseTarget.value) return

    this.knowledgeBaseChanged()
    this.entryChanged()
  }

  knowledgeBaseChanged(event) {
    const knowledgeBase = this.knowledgeBaseTarget
    const entry = this.entryTarget

    Rails.ajax({
      url: knowledgeBase.dataset.url,
      type: 'get',
      data: `id=${knowledgeBase.value}`,
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      },
      beforeSend(xhr, options) {
        xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8')
        // Workaround: add options.data late to avoid Content-Type header to already being set in stone
        // https://github.com/rails/rails/blob/master/actionview/app/assets/javascripts/rails-ujs/utils/ajax.coffee#L53
        return true
      },
      success: (partial) => {
        console.log(partial)

        entry.innerHTML = `<option value="" label=""></option>`

        if (!partial) partial = []
        partial.forEach(entryAsPair => {
          entry.innerHTML += `<option value="${entryAsPair[0]}">${entryAsPair[1]}</option>`
        })
      },
      error: (e) => {
        console.log('error updating')
        console.log(e)
      }
    })
    // this.entryTarget.innerHTML = "<option></option>"
  }

  entryChanged(event) {
    const entry = this.entryTarget
    const section = this.sectionTarget

    Rails.ajax({
      url: entry.dataset.url,
      type: 'get',
      data: `id=${entry.value}`,
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      },
      beforeSend(xhr, options) {
        xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8')
        // Workaround: add options.data late to avoid Content-Type header to already being set in stone
        // https://github.com/rails/rails/blob/master/actionview/app/assets/javascripts/rails-ujs/utils/ajax.coffee#L53
        return true
      },
      success: (partial) => {
        console.log(partial)

        section.innerHTML = `<option value="" label=""></option>`

        if (!partial) partial = []
        partial.forEach(sectionLink => {
          section.innerHTML += `<option value="${sectionLink}">${sectionLink}</option>`
        })
      },
      error: (e) => {
        console.log('error updating')
        console.log(e)
      }
    })
  }
}
