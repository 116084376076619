import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["priority", "option", "input"]

  connect() {
    console.log('priority connect')

    // this.tabTargets[0].toggleTransition()
  }

  changePriority(event) {
    const svg = this.priorityTarget.children[0]

    // zmiana kolorów gwiazdek
    const oldColor = this.priorityTarget.dataset.currentColor
    if (oldColor) {
      svg.classList.remove(...oldColor.split(' '))
    }
    const newColor = event.params.color
    if (newColor) {
      svg.classList.add(...newColor.split(' '))
    }

    // zmiana wypełnienia/obwódki - zerowy priorytet ma obwódkę, reszta ma wypełnienie
    if ((newColor && !oldColor) || (!newColor && oldColor)) {
      const fill = svg.getAttribute("fill")
      svg.setAttribute("fill", svg.getAttribute("stroke"))
      svg.setAttribute("stroke", fill)
    }

    this.priorityTarget.dataset.currentColor = newColor

    this.inputTarget.value = event.params.value
  }
}
