import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["intersector", "block", "test", "container"]
  connect() {
    console.log('scroll connect')
    this.addShadow()
  }

  disconnect() {
  }

  addShadow() {
    const header = this.containerTarget
    const list = this.blockTarget
    const height = this.testTarget
    const scrollMarker = this.intersectorTarget

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) {
          header.classList.add("shadow-md")
          list.classList.add("!flex")
          height.classList.remove("h-0")
        } else {
          header.classList.remove("shadow-md")
          list.classList.remove("!flex")
          height.classList.add("h-0")
        }
      })
    }, {})

    observer.observe(scrollMarker)
  }
}
