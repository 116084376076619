import { Controller } from "@hotwired/stimulus"

/* przykład użycia: app/views/design/uix/stimulus.html.erb */

export default class extends Controller {
  static targets = ["theme", "darkbutton", "lightbutton", "defaultbutton"]

  connect() {
    // ustawienie pozycji scrolla na odpowiedniej wysokości
    if (!this.isMobileVariant) {
      var pathName = document.location.pathname
      window.onpagehide = function () {
        var scrollPosition = document.querySelector('main').scrollTop
        sessionStorage.setItem("scrollPosition_" + pathName, scrollPosition)
      }
      if (window.performance && window.performance.navigation.type === window.performance.navigation.TYPE_BACK_FORWARD) {
        if (sessionStorage["scrollPosition_" + pathName]) {
          document.querySelector('main').scrollTop = sessionStorage.getItem("scrollPosition_" + pathName)
        }
      }
    }

    // Change the icons inside the button based on previous settings
    this.setColor()
  }

  setColor() {
    this.defaultbuttonTarget.classList.add('text-gray-700')
    this.defaultbuttonTarget.classList.remove('text-blue-700')

    this.darkbuttonTarget.classList.add('text-gray-700')
    this.darkbuttonTarget.classList.remove('text-blue-700')

    this.lightbuttonTarget.classList.add('text-gray-700')
    this.lightbuttonTarget.classList.remove('text-blue-700')

    if (!('color-theme' in localStorage)) {
      this.defaultbuttonTarget.classList.remove('text-gray-700')
      this.defaultbuttonTarget.classList.add('text-blue-700')
    } else {
      if (localStorage.getItem('color-theme') === 'dark') {
        this.darkbuttonTarget.classList.remove('text-gray-700')
        this.darkbuttonTarget.classList.add('text-blue-700')
      } else {
        this.lightbuttonTarget.classList.remove('text-gray-700')
        this.lightbuttonTarget.classList.add('text-blue-700')
      }
    }
  }

  toggle(event) {
    const mode = event.params.mode

    if (mode === "default") {
      if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
        this.themeTarget.classList.add('dark')
      } else {
        this.themeTarget.classList.remove('dark')
      }
      localStorage.removeItem('color-theme')
    } else {
      if (mode === "dark") {
        this.themeTarget.classList.add('dark')
        localStorage.setItem('color-theme', 'dark')
      } else {
        this.themeTarget.classList.remove('dark')
        localStorage.setItem('color-theme', 'light')
      }
    }
    this.setColor()
  }

  get isMobileVariant() {
    return document.head.querySelector("meta[name=app_variant]").content === "mobile"
  }

  disconnect() {
  }
}
