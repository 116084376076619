import { Controller } from "@hotwired/stimulus"
// import { useTransition } from 'stimulus-use'

/* przykład użycia: app/views/design/uix/stimulus.html.erb */

export default class extends Controller {
  static targets = ["form", "field", "list", "priority", "menu", "placeholder", "template", "selectTemplate"]
  lastIndex = 0 // liczba pól formularza
  currentDropTarget = null // obecny obiekt nad którym przenosimy pole formularza
  currentSettings = null // obecnie rozwinięte menu opcji pola formularza
  modal = null // modal do edycji pól formularza na małych ekranach

  connect() {
    console.log('connect forms')
    this.lastIndex = this.fieldTargets.length
    this.modal = document.getElementById('form_menu_modal')
  }

  updateField(event) {
    let id = ""
    if (event.params.kind) {
      id = `form_field_${event.params.index}_${event.params.kind}`
    } else {
      id = event.target.id
    }
    const placeholder = document.getElementById(`${id}_placeholder`)
    console.log(id)
    if (!event.target.value) {
      event.target.value = event.target.dataset.placeholder || ''
    }
    placeholder.textContent = event.target.value
  }

  checkCheckbox(event) {
    const field = event.params.field
    const target = document.getElementById(`${field}_1`)
    let addClass = ["", ""]
    let removeClass = ["", ""]
    let checked = null
    const checkbox = document.getElementById(field)

    if (target.classList.contains('bg-blue-400')) {
      // ustawienie False
      checkbox.checked = false
      checkbox.value = "0"
      addClass = ["bg-gray-300", null]
      removeClass = ["bg-blue-400", "translate-x-4"]
      checked = false
    } else {
      // ustawienie True
      checkbox.checked = true
      checkbox.value = "1"
      addClass = ["bg-blue-400", "translate-x-4"]
      removeClass = ["bg-gray-300", null]
      checked = true
    }

    target.classList.add(addClass[0])
    target.classList.remove(removeClass[0])
    if (addClass[1]) {
      target.childNodes[1].classList.add(addClass[1])
    } else {
      target.childNodes[1].classList.remove(removeClass[1])
    }

    return checked
  }

  // Dodanie nowego pola w formularzu
  addField(event) {
    const templateRight = this.templateTargets.find((template) => template.id === `form_field_menu_template_${event.params.kind}`)
    const templateMiddle = this.templateTargets.find((template) => template.id === `form_field_template_${event.params.kind}`)
    if (!templateRight || !templateMiddle) {
      return false
    }
    const tempDiv = document.createElement('div')

    this.lastIndex += 1
    const newListElementRight = templateRight.innerHTML.replaceAll(templateRight.dataset.index, this.lastIndex)
    tempDiv.innerHTML = newListElementRight
    document.getElementById(`right-container`).appendChild(tempDiv.firstElementChild)

    const newListElementMiddle = templateMiddle.innerHTML.replaceAll(templateMiddle.dataset.index, this.lastIndex)
    tempDiv.innerHTML = newListElementMiddle
    document.getElementById(`form_fields`).appendChild(tempDiv.firstElementChild)
  }

  // Po zmianie nazwy pola formularza aktualizujemy jego nazwę na liście
  changeName(event) {
    const name = document.getElementById(`form_field_${event.params.index}_name`)
    name.textContent = event.target.value
  }

  // Po zaznaczeniu pola formularza jako wymagane odkrywamy czerwoną * przy jego nazwie na liście
  changeRequired(event) {
    const checked = this.checkCheckbox(event)
    const required = document.getElementById(`form_field_${event.params.index}_required`)

    if (checked) {
      required.classList.remove("hidden")
    } else {
      required.classList.add("hidden")
    }
  }

  // Po zmianie opcji dla pól wyboru formularza aktualizujemy opcje na liście pól
  changeOptions(event) {
    const list = document.querySelector(`#form_field_${event.params.index}_${event.params.kind}`)
    list.innerHTML = ''

    const options = event.target.value.split(/\n/)
    options.forEach(option => {
      this.addOption(list, event.params.kind, option)
    })
  }

  // Dodanie pojedynczej opcji pola wyboru na liście pól (patrz changeOptions)
  addOption(list, kind, option) {
    const template = this.selectTemplateTargets.find((template) => template.id === `${kind}_template`)

    if (!template) {
      return false
    }

    const newListElement = template.content.cloneNode(true)
    newListElement.querySelector('label').textContent = option
    list.appendChild(newListElement)
  }

  // Jeśli nie chcemy by kliknięty enter w danym polu w edycji formularza zapisywał cały formularz
  // dodajemy do niego data-action="keydown.enter->forms#enterClick"
  enterClick(event) {
    event.preventDefault()
    event.target.blur()
  }

  // Pokazywanie i ukrywanie opcji pojedynczego pola formularza
  // (toggle z dropdown_controllera nie wpółpracował z drag&drop używanym w formularzach)
  toggle(event) {
    const menu = this.menuTargets.filter(menu => menu.id === `form_field_menu_${event.params.index}`)[0]

    if (menu === this.currentSettings) {
      this.toggleHelp(menu, true)
      this.currentSettings = null
    } else {
      this.toggleHelp(this.currentSettings, true)
      this.toggleHelp(menu, false)
      this.currentSettings = menu
    }
  }

  toggleHelp(element, hide) {
    if (!element) {
      return false
    }
    const full = element.querySelector(".form_field_menu_full")
    const short = element.querySelector(".form_field_menu_short")

    this.showModal(full, hide)

    if (hide) {
      full.classList.add('hidden')
      element.classList.remove('sticky')
      element.classList.remove('top-0')
      short.classList.remove('hidden')
    } else {
      element.classList.add('sticky')
      element.classList.add('top-0')
      full.classList.remove('hidden')
      short.classList.add('hidden')
    }
  }

  showModal(element, hide) {
    const innerModal = this.modal.querySelector('#form_menu_settings_modal')
    if (hide) {
      innerModal.innerHTML = ''
      this.modal.classList.add('hidden')
    } else {
      this.modal.querySelector("#form_menu_modal_title").textContent = element.querySelector('.form_menu_title').textContent

      const template = this.modal.querySelector(`#form_field_menu_template_modal_${element.dataset.kind}`)
      if (!template) {
        return false
      }
      const tempDiv = document.createElement('div')

      const newModal = template.innerHTML.replaceAll(template.dataset.index, element.dataset.index)

      tempDiv.innerHTML = newModal
      const newElements = tempDiv.firstElementChild.querySelectorAll(`[id^="form_modal_${element.dataset.index}"]`)
      const currentElements = element.querySelectorAll(`[id^="form[form_fields_attributes][${element.dataset.index}]"]`)

      for (let i = 0; i < newElements.length; i++) {
        newElements[i].value = currentElements[i].value
        newElements[i].innerHTML = currentElements[i].innerHTML
        newElements[i].classList = currentElements[i].classList
      }
      innerModal.appendChild(tempDiv.firstElementChild)

      this.modal.classList.remove('hidden')
    }
  }

  updateFromModal(event) {
    const targetId = event.target.id || event.target.parentElement.id // w przypadku checkboxa może wystąpić potrzeba wzięcia ID od rodzica
    const idEnd = targetId.substring(targetId.indexOf(event.params.index) + 1)
    const id = `[${event.params.index}]${idEnd}`

    const input = document.querySelector(`[id*="${id}"]`)
    const newEvent = new Event("click")

    switch (input.tagName) {
      case "INPUT": case "TEXTAREA":
        input.value = event.target.value
        console.log(input)
        input.dispatchEvent(new Event("input", { params: event.params, target: input }))
        break
      case "DIV": // checkbox
        newEvent.params = { field: id.slice(0, id.lastIndexOf('_')) }
        input.dispatchEvent(newEvent)
        break
    }
    event.preventDefault()
  }

  updateFromField(event) {
    const targetId = event.target.id || event.target.parentElement.id // w przypadku checkboxa może wystąpić potrzeba wzięcia ID od rodzica
    const idEnd = targetId.substring(targetId.indexOf(event.params.index) + 2)
    const id = `form_modal_${event.params.index}${idEnd}`
    const input = document.querySelector(`[id*="${id}"]`)
    const newEvent = new Event("click")
    switch (input.tagName) {
      case "INPUT": case "TEXTAREA":
        input.value = event.target.value
        break
      case "DIV": // checkbox
        newEvent.params = { field: id.slice(0, id.lastIndexOf('_')) }
        this.checkCheckbox(newEvent)
        break
    }
    event.preventDefault()
  }

  submitForm(event) {
    const isValid = this.validateForm()

    // If our form is invalid, prevent default on the event
    // so that the form is not submitted
    if (!isValid) {
      event.preventDefault()
    }
  }

  validateForm() {
    const isValid = this.formTarget.checkValidity()
    let focused = false

    // Tell the browser to find any required fields
    const requiredFieldSelectors = 'textarea:required, input:required'
    const requiredFields = this.formTarget.querySelectorAll(requiredFieldSelectors)

    requiredFields.forEach((field) => {
      // For each required field, check to see if the value is empty
      // if so, we focus the field and set our value to false
      if (!field.disabled && !field.checkValidity()) {
        field.classList.remove('focus:ring-blue-500')
        field.classList.add('focus:ring-red-400')
        field.classList.add('focus:border-red-400')
        if (!focused) {
          const menu = field.closest(".form_field_menu_full")
          if (menu && menu.classList.contains("hidden")) {
            menu.querySelector('[data-action^="click->forms#toggle"]').click()
          }
          focused = true
          field.focus()
        }
      } else {
        field.classList.add('focus:ring-blue-500')
        field.classList.remove('focus:ring-red-400')
        field.classList.remove('focus:border-red-400')
      }
    })

    return isValid
  }

  // DRAG&DROP START

  // Akcja po rozpoczęciu przeciągania elementu
  // chwilowo zmniejszamy opacity elementu na stronie
  dragstart(event) {
    if (event.target.getAttribute('data-drag-id')) {
      this.toggle(event)

      event.dataTransfer.setData("application/drag-key", event.target.getAttribute("data-drag-id"))
      event.target.querySelector('.form_field_element').classList.add('opacity-20')
      event.dataTransfer.effectAllowed = "move"
    } else {
      event.preventDefault()
      event.stopPropagation()
    }
  }

  // Akcja na elemencie nad którym przenosimy inny w momencie rozpoczęcia przechodzenia nad nim
  dragenter(event) {
    const currentDropTarget = this.getDropTarget(event)
    if (currentDropTarget) {
      const oldDropTarget = this.currentDropTarget
      this.currentDropTarget = currentDropTarget.querySelector('.form_field_placeholder')

      if (oldDropTarget && this.currentDropTarget !== oldDropTarget) {
        oldDropTarget.classList.add('invisible')
      }

      this.currentDropTarget.classList.remove('invisible')
    }
    event.preventDefault()
  }

  // Akcja na elemencie nad którym przenosimy inny w trakcie przechodzenia nad nim
  dragover(event) {
    event.preventDefault()
    return true
  }

  // Akcja na elemencie nad którym przenosimy inny po zakończeniu przechodzenia nad nim
  dragleave(event) {
  }

  // Akcja upuszczenia elementu na stronie
  drop(event) {
    const draggedItem = this.getDragTarget(event)
    const dropTarget = this.getDropTarget(event)

    this.dropHelper(draggedItem, dropTarget)

    draggedItem.querySelector('.form_field_element').classList.remove('opacity-20')
    Array.from(document.querySelectorAll('.form_field_placeholder')).map(placeholder => placeholder.classList.add('invisible'))
    // this.currentDropTarget.classList.add('invisible')
    this.currentDropTarget = null
    event.preventDefault()
  }

  // Przenosi na liście pola formularza (i z środkowej części i z prawej części)
  dropHelper(draggedItem, dropTarget) {
    if (this.getPriority(draggedItem) < this.getPriority(dropTarget)) {
      dropTarget.insertAdjacentElement('afterend', draggedItem)
      this.getMenu(dropTarget).insertAdjacentElement('afterend', this.getMenu(draggedItem))
    } else {
      dropTarget.insertAdjacentElement('beforebegin', draggedItem)
      this.getMenu(dropTarget).insertAdjacentElement('beforebegin', this.getMenu(draggedItem))
    }
  }

  // Akcja po zakończeniu przenoszenia elementu
  // W tym miejscu ustawiamy nowy priorytet dla pól, na podstawie którego pola zachowują ustawioną kolejność
  dragend(event) {
    // zmiana wartości priorytetów
    let priority = 1
    this.priorityTargets.forEach(field => {
      field.value = priority
      priority += 1
    })
  }

  // Pomocnicza funkcja zwracająca obiekt nad którym przenosimy element
  getDropTarget(event) {
    if (!event.target.getAttribute('data-drag-id')) {
      return event.target.closest('[data-drag-id]')
    } else {
      return event.target
    }
  }

  // Pomocnicza funkcja zwracająca obiekt który przenosimy
  getDragTarget(event) {
    const data = event.dataTransfer.getData("application/drag-key")
    return this.element.querySelector(`[data-drag-id='${data}']`)
  }

  // Pomocnicza funkcja zwracająca obecny priorytet pola (używana do przenoszenia pól)
  getPriority(element) {
    if (!element) {
      return -1
    }

    return parseInt(this.getMenu(element).querySelector(`[data-forms-target=priority]`).value)
  }

  // Pomocnicza funkcja zwracająca prawe menu odpowiadające wskazanemu elementowi
  getMenu(element) {
    return this.menuTargets.filter(menu => menu.id === `form_field_menu_${element.dataset.dragId}`)[0]
  }

  moveUp(event) {
    const target = event.target.closest('[data-drag-id]')
    this.dropHelper(target, target.previousElementSibling)
    this.dragend(event)
  }

  moveDown(event) {
    const target = event.target.closest('[data-drag-id]')
    this.dropHelper(target, target.nextElementSibling)
    this.dragend(event)
  }
  // DRAG&DROP END
}
