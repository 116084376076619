export const appstorePaths = { // TODO: Wyciągnąć z tego obiektu ściezki które powinny być w connectPaths/inConnectorPaths
  appstore_appstore_index_path: '/appstore/appstore',
  get_context_apps_appstore_appstore_index_path: '/appstore/appstore/get_context_apps',
  data_objects_path: "/connect/data_objects",
  flow_processes_path: "/connect/flow_processes",
  apps_path: "/connect/apps",
  connectors_path: "/connect/connectors",
  direct_uploads_path: "/file/attachments/direct_uploads",
  action_connect_connector_path: '/connect/connector/_code_',
  action_connect_app_path: '/connect/app/_code_'
}

export const insightPaths = {
  insight_reports_path: '/insight/reports'
}
